<template>
  <div class="form-group">
    <div class="timepicker-container">
      <select v-model="hour" name="month" class="form-control" >
        <option value="">Hour</option>
        <option v-for="hr in hours" :key="`hr-${hr}`" :value="hr">{{ hr }}</option>
      </select>
      <span>:</span>
      <select v-model="minute" name="day" class="form-control" >
        <option value="">Minute</option>
        <option v-for="(min, index) in minutes" :key="`min-${index}`" :value="min">{{ min }}</option>
      </select>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      required: false,
      type: [String, Number],
      default: '',
    },
    nightOff: {
      required: false,
      type: Boolean,
      default: false,
    }
  },

  computed: {
    hours() {
      let options = []
      for(let i = 0; i <= 23; i++) {
        if (this.nightOff && (i >= 0 && i <= 7 || i >= 20 && i <= 23 )) {
          continue;
        }
        options.push(('00' + i).slice(-2))
      }

      return options
    },

    minutes() {
      let options = []

      for(let i = 0; i <= 50; i++) {
        if (i % 10 !== 0) continue;

        options.push(('00' + i).slice(-2))
      }

      return options
    },

    hour: {
      get() {
        if (!this.value) return '12';

        return this.value.split(':')[0]
      },

      set(newValue) {
        if (newValue !== "" && this.minute !== "") {
          this.$emit('input', `${newValue}:${this.minute}`)
        }
      },
    },
    minute: {
      get() {
        if (!this.value) return '00';

        return this.value.split(':')[1]
      },

      set(newValue) {
        if (newValue !== "" && this.hour !== "") {
          this.$emit('input', `${this.hour}:${newValue}`)
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.timepicker-container {
  display: flex;
      align-items: center;
  span {
    padding: 0px 10px;
  }
}
</style>